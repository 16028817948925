import React from 'react';
import { download, pathnameToKey } from '../functions/download'
import { Card, Image, Button, Text, Flex } from '@aws-amplify/ui-react';

export const ImageCard: React.FC<any> = ({ signedURL, downloadFilePath }) => (
    <Card
      variation="outlined"
      width="15rem"
      height="18rem"
      borderRadius="medium"
      borderColor="var(--amplify-colors-neutral-40)"
      padding="0.5rem"
      backgroundColor="var(--amplify-colors-white)"
    >
      <Image
        borderRadius="small"
        alt={signedURL.url.pathname}
        src={signedURL.url.href}
        objectFit="cover"
        objectPosition="50% 50%"
        width="100%"
        height="77%"
      />
      <Flex
        direction="row"
        justifyContent="center"
        wrap="wrap"
      >
        <Text
          variation='tertiary'
          as='p'
          fontWeight={500}
          fontSize="0.8em"
          fontStyle="normal"
        >
          {pathnameToKey(signedURL.url.pathname).slice(0,28)}
        </Text>
      </Flex>
      <Button
        size="small"
        variation="link"
        colorTheme='overlay'
        isFullWidth
        onClick={() => download(downloadFilePath + pathnameToKey(signedURL.url.pathname))}
      >
        Download
      </Button>
    </Card>
);