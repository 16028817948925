import React from 'react';
import { View } from "@aws-amplify/ui-react"
import { Navbar } from './components/Navbar';
import { Gallary } from './components/Gallary';
import { Footer } from './components/Footer';

const App: React.FC = () => {
  return (
  <View as='div' backgroundColor="white" padding="1rem">
    <View
      as='div'
      backgroundColor="white"
      padding="1rem"
      border="1px solid var(--amplify-colors-neutral-40)"
      borderRadius="medium"
    >
      <Navbar />
      <Gallary />
      <Footer />
    </View>
  </View>
  );
};

export default App;
