import { getUrl } from 'aws-amplify/storage';

export function pathnameToKey(pathname: string): string {
    return pathname.split("/").pop() as string
  }

async function getObjectUrl (key: string) {
  let res = await getUrl({
    key: key,
    options: {
      accessLevel: 'guest',
      validateObjectExistence: true,
      expiresIn: 900,
      useAccelerateEndpoint: true,
    }
  })
  return res
}

export async function download(key: string): Promise<void> {
  const signedURL = await getObjectUrl(key)
  const filename = pathnameToKey(signedURL.url.pathname)

  const res: Response = await fetch(signedURL.url.href)
  const blob: Blob = await res.blob()
  const base64: string = URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.download = filename
  anchor.href = base64
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
  
  URL.revokeObjectURL(base64)
}