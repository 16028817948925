import { list, getUrl, GetUrlOutput } from 'aws-amplify/storage';
import React, { useEffect, useState } from 'react';
import { ImageCard } from './ImageCard';
import { VideoCard } from './VideoCard';
import { Collection, Divider, Flex, Loader, Text } from '@aws-amplify/ui-react';


export const Gallary: React.FC = () => {

  const [loadingPictures, setLoadingPictures] = useState<boolean>(false)
  const [loadingVideos, setLoadingVideos] = useState<boolean>(false)
  const [thumbnailSignedURLs, setThumbnailSignedURLs] = useState<GetUrlOutput[]>([])
  const [videoSignedURLs, setVideoSignedURLs] = useState<GetUrlOutput[]>([])
  const BUCKET_PATH_IMAGE_THUMBNAILS: string = "photos/thumbnails/"
  const BUCKET_PATH_IMAGE_ORIGINALS: string = "photos/originals/"
  const BUCKET_PATH_VIDEOS: string = "videos/"

  useEffect(() => {

    const fetchMedia = async (filePath: string, callback: any, loading: any) => {
      loading(true)
      let res = await list({ prefix: filePath })

      // Get rid of the first item in the returned array which is the folder itself
      let resWithoutTopFolder = res.items.slice(1)

      const signedURLs = await Promise.all(
        resWithoutTopFolder.map(async item => getObjectUrl(item.key))
      )
      callback(signedURLs)
      loading(false)
    }

    const getObjectUrl = async (key: string) => {
      let res = await getUrl({
        key: key,
        options: {
          accessLevel: 'guest',
          validateObjectExistence: true,
          expiresIn: 900,
          useAccelerateEndpoint: true,
        }
      })
      return res
    }

    fetchMedia(BUCKET_PATH_IMAGE_THUMBNAILS, setThumbnailSignedURLs, setLoadingPictures)
    fetchMedia(BUCKET_PATH_VIDEOS, setVideoSignedURLs, setLoadingVideos)
  }, []);

  const loader = () => {
    return(
      <Flex direction="row" justifyContent="center">
        <Loader
          width="5rem"
          height="5rem"
          emptyColor="var(--amplify-colors-neutral-40)"
          filledColor="var(--amplify-colors-neutral-80)"
        />
      </Flex>
    )
  }

  const sectionTitle = (titel: string) => {
    return(
      <Text variation='tertiary' as='p' fontWeight={200} fontSize="1.0em" fontStyle="normal">
        { titel }
      </Text>
    )
  }

  return (
    <>
      { sectionTitle("Pictures") }
      {loadingPictures && (
        loader()
      )}
      {!loadingPictures && (
        <Collection
          items={thumbnailSignedURLs}
          type='list'
          direction="row"
          gap='20px'
          wrap="wrap"
          marginTop="1rem"
        >
          {(item, index) => (
            <ImageCard key={index} signedURL={item} downloadFilePath={BUCKET_PATH_IMAGE_ORIGINALS}></ImageCard>
          )}
        </Collection>
      )}
      <Divider
        marginTop="1rem"
        marginBottom="1rem"
        orientation="horizontal"
        size='small'
        borderColor="var(--amplify-colors-neutral-40)"
      />
      { sectionTitle("Videos") }
      {loadingVideos && (
        loader()
      )}
      {!loadingVideos && (
        <Collection
          items={videoSignedURLs}
          type='list'
          direction="row"
          gap='20px'
          wrap="wrap"
          marginTop="1rem"
        >
          {(item, index) => (
            <VideoCard key={index} signedURL={item} downloadFilePath={BUCKET_PATH_VIDEOS}></VideoCard>
          )}
        </Collection>
      )}
    </>
  )
}