import React from 'react';
import { View, Flex, Divider, Icon } from '@aws-amplify/ui-react';

export const Navbar: React.FC = () => (
  <View
    as="div"
    backgroundColor="var(--amplify-colors-white)"
    maxWidth="100%"
  >
    <Flex
      direction="row"
      justifyContent="center"
    >
      <Icon
        pathData="M502,221c48.1,0,74-25.9,74-74V74c0-48.1-25.9-74-74-74H0v300h68v-79H502z M508,78v65c0,7.8-4.2,12-12,12H68V66h428 	C503.8,66,508,70.2,508,78z M736,300c-48.1,0-74-25.9-74-74V74c0-48.1,25.9-74,74-74h417c48.1,0,74,25.9,74,74v152 	c0,48.1-25.9,74-74,74H736z M1147,234c7.8,0,12-4.2,12-12V78c0-7.8-4.2-12-12-12H742c-7.8,0-12,4.2-12,12v144c0,7.8,4.2,12,12,12 	H1147z M1822,198c39.8444,16.7574,67.8527,56.1,68,102h-68c0-54-25-79-79-79h-361v79h-68V0h502c48.1,0,74,25.9,74,74v50.14 	C1890,170.2,1866.25,195.9,1822,198z M1810,155c7.8,0,12-4.2,12-12V78c0-7.8-4.2-12-12-12h-428v89H1810z M1972,74 	c0-48.1,25.9-74,74-74h492v56h-486c-7.8,0-12,4.2-12,12v42c0,7.8,4.2,12,12,12h422c48.1,0,74,25.9,74,74v30 	c0,48.1-25.9,74-74,74h-492v-56h486c7.8,0,12-4.2,12-12v-42c0-7.8-4.2-12-12-12h-422c-48.1,0-74-25.9-74-74V74z M2633,74 	c0-48.1,25.9-74,74-74h480v66h-474c-7.8,0-12,4.2-12,12v144c0,7.8,4.2,12,12,12h474v66h-480c-48.1,0-74-25.9-74-74V74z 	 M3817,0v300h-68V183h-407v117h-68V0h68v117h407V0H3817z M3973,56v66h527v56h-527v66h527v56h-595V0h595v56H3973z"
        viewBox={{
          width: 4500,
          height: 300,
        }}
        width="230"
        height="25"
        ariaLabel="Porsche"
      />
    </Flex>
    <Divider
      marginTop="1rem"
      marginBottom="1rem"
      orientation="horizontal"
      size='small'
      borderColor="var(--amplify-colors-neutral-40)"
    />
  </View>
);